import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Patterns and Reality",
  "date": "2021-11-17T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Of the 600 thousand billion billion (600,000,000,000,000,000,000,000) planets in our universe, our planet is the only one, as far as we know, that supports life. With odds like that, we should feel lucky to be alive. Yet many of us can’t help but feel a sense of foreboding as we look up into the lonely dark of night, with its cold little stars shining like ice. Why are we here? How are we here? What are we to do? Living in the early 21st century, we have inherited a scientistic worldview that has displaced the stories our ancestors would tell each other in answer to these very same questions. Our new and improved worldview has given rise to previously-unimaginable technological wonders, but it has also left us with a barren landscape where once stood monuments to the collective spiritual wisdom of generations. Spirit is no longer part of our ontology, so the monuments are no longer required.`}</p>
    <p>{`Our scientism, however, offers up no replacement for that which it breaks down. We have finally internalized the ontology that Democritus recommended over 2,000 years ago: “…in truth there are only atoms and the void.” What happens when you internalize such a message? As a society, we have become lost, nihilistic, alienated, anxious and depressed. Some thinkers have labeled our current situation as a “crisis of meaning”. If atoms and a void are all there is to reality, and our lives are the result of mechanistic, uncaring processes unfolding chaotically, what meaning is there to find? Does it matter what we spend our time on? What habits we foster in ourselves? How we treat others? Even those who might otherwise be drawn to altruism wonder why they should act as though life is valuable when those who act otherwise enjoy material success — the only success that matters, according to our materialistic culture.`}</p>
    <p>{`Linking our crisis of meaning to scientism is not an excoriation of science. There is nothing amiss with the scientific method. The problem is when we move too quickly from celebrating what physics has discovered to believing that its current taxonomy lists the totality of what exists, as if physics was complete. It’s not. Of course, we’re unlikely to learn something that will entirely upend these discoveries, but we may learn things that make us reinterpret past results, like the move from Newtonian, “classical”, physics to Einstein’s relativity. This type of scientific revolution happens from time to time, and we need to look at our situation historically; physicists are all-too-aware of the mysteries in their field, and it looks more and more like a scientific revolution may be required to solve them. Too often, though, we take existing scientific knowledge to be not only true, but the exhaustive truth. We take a lack of evidence as evidence of a lack (a lack of evidence might come from a bias against studying a particular phenomenon, whether attitudinally or in terms of financial incentives, for example). And we seem to have made some unspoken cultural agreement that something’s existence is exhausted by its parts; that if we are made of atoms and a void, we are only atoms and a void, and nothing else, for ever and ever amen. If this premise could be challenged, we would at least open the door to a reclamation of spiritual goods because the tendency to dismiss spiritual concepts rests, in part, on the bias toward a minimalistic, scientistic ontology.`}</p>
    <p>{`One worry might be that trying to find meaning in this world is contrived; that any narrative we can produce to fill the meaning vacuum is an exercise in self-deception and encourages credulity. This is a valid worry, and it speaks against simply adopting any old belief system that is on offer. Back in 1877, the philosopher William Kingdon Clifford argued that adopting beliefs without reason and evidence is not only inadvisable, but immoral. This is an argument against putting blind faith in a religious framework, and it’s difficult to disagree with it. We have seen the harm that can come from confidence in an irrational belief and it’s not trivial; religion can claim responsibility for millions of deaths. Sometimes religion is used as a cover for more insidious motives, but sometimes it is the soil from which those motives grow. This is why it’s important to understand that a project to recover spirituality in a material world can’t take the form of a conservative appeal to tradition, but must rather be an honest examination of all the evidence before us, without a bias toward either scientism or religiosity, to discover whether spiritual concepts might have any reality, and if so, how we should think about them.`}</p>
    <p>{`This article represents one part of such an attempt. The recommendation is bold, but surprisingly in line with the thrust of bleeding edge physical theories: Instead of atoms and a void, we should understand that at root reality is composed of patterns of information. This is counterintuitive to we humans, who have thoroughly bought into the illusion of a solid, external world. How can patterns of information be responsible for the hefty solidity of a boulder? Information is not extended in space; it does not have mass; it does not have texture. To articulate this idea in its fullness requires a careful examination of physics, physiology and psychology. This article will not engage with the ontology of information, but rather of pattern. Pattern is the root of real things, as we understand them, and so it will be recommended that we consider pattern to be basic to our ontology — not particles or quantum fields or one-dimensional vibrating strings. Indeed, what is common to each of these domains (and many more up the ladder of scale, to entire galaxies), and common to the relation between them, is pattern. A recalibration of our ontological intuitions to take pattern as basic is key to not only understanding more about our reality, but to reclaiming spiritual goods in an honest and rationally motivated way.`}</p>
    <p>{`What spiritual goods are worth reclaiming? At minimum, we could rediscover the weighty reality of the ethical world, something that has diminished in our collective minds as postmodernism’s relativism has wrapped its tendrils around our institutions. This is not to say that the specifics of any particular ethical theory will emerge unscathed, but the basics, covered by virtually every moral theory and religion, that enable social cohesion and efficiency could regain their imperative force through understanding our society as a particular type of pattern — a superorganism — and moral action as the relations that, in part, define that pattern. However, we may achieve more than the minimum. Spiritual ideas like the soul and its survival after death could be well understood within a new paradigm. What would such an understanding do for a lost society? Perhaps quite a bit, if the new paradigm was internalized as thoroughly as Democritus’ “atoms and a void” ontology has been in our current age.`}</p>
    <p>{`Emergence and Eliminative Materlialism
On the other hand, the minimalist ontology (sometimes called “eliminative materialism”) is efficient: there are not many different kinds of things—a handful of quantum fields and ripples within them. Everything else is illusory. If your interpretation of Occam’s Razor is to be sparing in your ontological commitments, this is just the theory for you. But alas for eliminative materialism. As far back as Descartes, at least, we have recognized that there are only two kinds of things whose existence cannot be doubted; a thinker and its thoughts. From an evidentiary perspective, we know about these two kinds of things with far more certainty than we know about quantum fields. Only someone very educated could convince themselves that the latter is real and the former is not.`}</p>
    <p>{`Those who defend eliminative materialism would say that thoughts and thinkers are illusions, and only weakly emergent properties of the underlying energetic substrate. But there is no principled reason to deny the reality of patterns at all other scales unless motivated by an arbitrary interpretation of Occam’s Razor, and there are really no resources within eliminative materialism to explain the fact of even weak emergence: There is nothing in the laws (that we have discerned) at foundational material layers that guarantees larger-scale patterns forming from the entities and interactions at those layers. At best, we have invented new sciences to deal with the fact of weak emergence, like chaos and complex systems theory. While these sciences aim to model the relationship between lower and higher level layers of reality, they don’t explain why we should live in a universe with emergent patterns at all, let alone at multiple levels of existence, with layers of people made of cells, and cells of molecules, and molecules of atoms and, and atoms of ripples in quantum fields.`}</p>
    <p>{`This fact of emergence is not trivial, but often taken for granted. It’s easy to imagine a universe with an equal distribution of matter and antimatter, or a null cosmological constant, or any number of changes to our current universe that would preclude any higher-level patterns from forming within the basic stuff provided by the big bang. We should expect, of course, that anyone who looks out onto the world and notices that it supports layers of patterns must necessarily live in a world that supports layers of patterns (what is a person, after all, but a pattern built from smaller scale patterns). Scientists and philosophers call this the “anthropic principle”. However, it’s one thing to note that an observed universe must be such that it supports the existence of observers. It’s another to note that such a universe is not, under our current knowledge, inevitable.`}</p>
    <p>{`To solve this problem, we can choose from two flavours of multiverse theory: Either there are very many spatially distinct universes and at least one, due to sheer probability, supports life; or very many possible universes co-exist simultaneously, in the same space, as evolutions of the quantum wave function (the latter is often called, “The Many Worlds Interpretation of Quantum Physics”). Some of these evolutions, which represent branching possible universes, support life, and so it’s no surprise that we find ourselves in one of those because we couldn’t find ourselves in one of those that doesn’t. To accept one or the other of these explanations, though, is just to accept something like this: There are a large number of universes that contain the same basic particles as the one we inhabit, but those universes do not have life. Some don’t have molecules. Something is special about ours — specifically that in ours, nested patterns can form. This is a property of our universe that must be above and beyond all those intrinsic properties possessed by elementary particles. An eliminative materialist should not be happy about either of these two solutions.`}</p>
    <p>{`There are a few serious alternatives to eliminative materialism, despite its popularity. Only a handful take the evidence of our phenomenal lives seriously. Some take it so seriously that they move from a denial of epistemic access to a mind-independent world (because we can never really get outside of our consciousness to see what lies beyond), to the metaphysical opinion that there is no mind-independent world. This is idealism. Some are more subtle and acknowledge the epistemic barrier between experience and the mind-independent world, and try to avoid taking a specific stance on metaphysical or ontological matters. I suspect that something like this motivated Niels Bohr to champion the “Copenhagen Interpretation” of quantum mechanics. On this view, science is about mapping out the possibilities and correlations of experience rather than discovering the actual stuff of the world. What eliminative materialism, idealism, and some of these intermediate views have in common is a desire to reason from first principles. They just disagree about what is first: Mind or world. The desire to reason from first principles comes, in part, from a desire to avoid the danger of bias in starting with pre-existing, or high-level concepts. I doubt that it’s productive, or even possible, to do so completely. It’s better to be mindful of cognitive bias as a matter of procedure.`}</p>
    <p>{`Multiple Layers and Downward Causation
Thus it’s possible to approach the problem in a different way, not from first principles but from considered evidence, with a careful suspicion of personal bias. There do seem to be at least two quantitatively and qualitatively different realms or layers within reality: mind and world. Each is inhabited by radically different things, following very different laws. This dichotomous view of reality is called “dualism”, a position that Descartes himself famously championed (though Descartes himself began from first principles, recognizing thinker and thoughts as the most immediately knowable things; his later incorporation of the other denizens of reality is controversial and not exactly deductive).`}</p>
    <p>{`Dualism has some famous objections. For example, if the mind receives sense information from the body, and the body receives commands from the mind, the body and the mind must cause changes in one another. But how can something non-material cause a change in something material, and vice versa? It can’t, and so dualism must be false. In general, most people have been satisfied with this objection, but its success trades on there being only one kind of cause (what Aristotle called an “efficient cause”) and a specific, erroneous interpretation of immaterial.`}</p>
    <p>{`Efficient causation is the paradigm type of causation that you witness when playing billiards, as one ball hits the next, and so on. However, we have known for two millennia that there are more kinds of cause than one, and the relationship of the soul/mind/consciousness to the body is something Aristotle would have called a “formal cause”. In more recent times it has been called, “downward causation”.`}</p>
    <p>{`Downward causation is the process wherein the patterns that emerge at the larger-scale layer put constraints on the possible state space of the smaller-scale layer. There are numerous examples of this, but perhaps the most clarifying is phenomenon of Rayleigh-Bénard convection cells.¹ In certain heated fluids, “cells” (such as those in the video below) form due to the interaction of a number of physical processes. The cells, in turn, constrain the possible motion of the molecules inside the cells. The cells themselves (mereologically formed of nothing but the fluid molecules) are at a level of organization above the laws of the interactions of molecules, but nonetheless the cells restrict the movement of the molecules within them, affecting the set of possible positions the molecules could adopt.`}</p>
    <p>{`Downward causation at work in Rayleigh-Bénard Convection Cells
Clearly we can make sense of causation between two layers of reality — one of molecules and energy, and the other of “cells” — if we understand the nature of downward causation. The world of the mind and the world of the body are not causally connected like billiard balls colliding, but the mind exhibits downward causation on the body, which means that there is a narrowing of the body’s next possible states. Otherwise, the mind and the body are not the kinds of things that can interact; they keep to themselves, having relations proper to their station, with other entities in the same web of relations as themselves. They belong to different layers of reality, the mind and the body. This doesn’t make the mind some spooky or unnatural thing; it’s just another layer of emergent patterns, one as real as the other.`}</p>
    <p>{`Another objection to dualism can be dealt with quickly: Some people seem to assume that something is immaterial just in case it cannot have any causal relationships with the material world. If that is indeed the correct definition, dualism must fall. But there are plenty of examples of immaterial things that can have effects on material things. To name a few: economies, justice, loyalty, and narratives. In each case we might say that there is a material substrate to these things, and the causal efficacy lies in that substrate. But this is not quite right, given the interplay between layers of reality that we have examined above with respect to downward causation: Only part of a thing’s causal efficacy can be attributed to the substance of which that thing is made.`}</p>
    <p>{`For example, a decision to stop eating sugar will have a physical effect on the body’s constitution, but there is no decisive state — or even set of states — of the body’s parts that represents a decision to stop eating sugar. That decision has, as its material basis, a long-term set of patterns enacted by a person, extending outside the boundaries of skin and skull, into the social and material layers of reality. That decision’s very identity requires patterns in the world of which the body is a mere part. We treat the decision as occurring inside our brains, not because we feel it there, but because of cultural bias. Philosophers have, for decades now, have been arguing that the mind isn’t in the head, and these arguments don’t all just come down to semantics. Andy Clark and David Chalmers said back in 1998 what we now feel somewhat viscerally when it comes to our devices: That the tools in our environment that we couple with tightly are literally parts of our mind. They are certainly right in that the patterns that are identical with our minds have, as their material basis, neural and silicon hardware, at least.`}</p>
    <p>{`Does dualism emerge vindicated? In a way, yes: The present claim is not that pattern is the only real kind of thing, but rather that every pattern is real. So if there are two different patterns, then there are two kinds of real things. Of course, as we have seen, patterns can be nested, so instead of dualism we have a “multiplism” (a term used in other contexts, but coined uniquely here in contrast to dualism or monism). Whether it’s the “many worlds” interpretation of quantum mechanics, or our “multiplism” of layers (each of which is so unique in its objects and interactions that we have entirely different sciences studying them), we moderns are going to have to get comfortable with a crowded ontology. Sean Carroll has done a lovely job, in the context of the many worlds interpretation of quantum mechanics, in arguing against the interpretation of Occam’s razor that favours ontologically simple theories.⁴ It’s not true that an atom is “more real” than a person. However, in contradiction to the idealists, it’s also not true that a person is “more real” than an atom. Each of these are real insofar as they are patterns in the ultimate stuff of reality (information). It’s patterns that are real. A pattern is something wherein entities stand in regular relationships with other entities. These can be spatial relationships, like a quilted design, or they could be temporal relations or relations of scale such as those between notes in a melody.`}</p>
    <p>{`Real Patterns
Patterns are not mind-dependent as some may assume; a pattern is not in the eye of the beholder. Rather, as Daniel Dennett, with his work on real patterns³, has argued, whether something is a pattern has to do with the amount of information required to specify it. This is a deep discussion, and one to be fleshed out in another article, but it’s no accident that information and pattern are so tightly entwined. Part of the difficulty in expressing this view is convincing the interlocutor that specification and information, as well as pattern, is mind-independent and as much a part of the foundational layers of the world as quarks (if not moreso).`}</p>
    <p>{`An intuitive way to approach the reality of pattern is to look at our mathematical models of systems at various scales of size. My contention is that if these patterns weren’t real, we wouldn’t be able to model them with mathematics, the language of pattern. Successfully modeling a system mathematically indicates, but does not create or define, a pattern. A mathematical description of a system is an indication that there is a real pattern there to be described, but patterns may exist that we have not yet modeled or even recognized. Indeed, the promise of machine learning is to recognize the patterns that humans can’t recognize, or have biases against seeing.`}</p>
    <p>{`If patterns, rather than quantum fields, were fundamental in our popular ontology, then we would be quite a bit more open to discovering the patterns at every level, from the quantum to the qualitative, and we could feel fine about ascribing the entities in each of those realms equal reality. Quarks are real. But so are humans and thinkers and thoughts. For that matter, so are economies and organizations and biospheres (layers we will not discuss in this article, but where we are part of the material basis for their existence).`}</p>
    <p>{`Autopoiesis: The Pattern of Life
This shift in perspective can reveal some useful truths. The study of the brain has revealed that it is a pattern recognizer par excellence. But it is also a pattern generator, and most importantly a pattern maintainer. The latter ability, to maintain patterns, has been theorized to lie behind our perceptions, but is also more generally the root of life itself. The concept of homeostasis has been around in biology since at least 1849, but biologists Humberto Maturana and Francisco Varela generalized the concept as a way to understand life and cognition. Varela’s protege, Evan Thompson, has done much to expand on these ideas. The general view is that life is not to be defined as a collection of properties, and so cannot be fully understood by looking at its parts. Rather, life can only be recognized as a self-maintaining pattern. Not every pattern that is self-maintaining is a living thing, but all living things are self-maintaining patterns. The pattern in living systems is called autopoiesis, and more generally a self-maintaining pattern is called an autonomous system. Varela and Thompson have used varying definitions of autonomy, in the relevant sense, but they can be generalized as follows: An autonomous system is a network of processes, where each process in the network is maintained, materially and organizationally, by some subset of the other processes of the network. Viewed this way, as a network of processes, the autonomous system is a closed system; each process feeds back into the system in recurrent and re-entrant loops. Systems exhibiting this kind of organization are organizationally (or operationally) closed.`}</p>
    <p>{`In living things, from the single-cell to the most complex multicellular organisms, autonomy is realized through the pattern of metabolism — the homeostatic mechanism of life. This means that while the homeostatic systems of living things are organizationally closed, they are materially or energetically open (this has also been referred to as thermodynamic openness) — they use the material of their environments in their homeostatic processes, and such material needs to be continually renewed.`}</p>
    <p>{`Let’s call the layer of reality that we try to capture with the science of chemistry, the “chemical” layer of reality. This is the domain of molecules and chemical interactions. A living thing is materially made of entities from the chemical layer, but is not defined by those entities. It is defined by the large-scale pattern of interactions between objects of that layer, where that pattern is self-reinforcing (autopoietic, homeostatic). If it wasn’t for the fact that, in our universe, it takes specific chemicals to facilitate certain interactions, the material basis of a living thing would be virtually irrelevant. Without the parts there is no pattern, but without the pattern there is no life.`}</p>
    <p>{`Because we ourselves are multicellular organisms, we must realize that not only are we autonomous systems, but we are made, materially, of autonomous systems. Cells are like us in that they take in chemicals from the environment and, through a network of interactions, maintain that very network. There are other autonomous systems within us, however: Varela often used the nervous system and the immune system as examples. These are also homeostatic systems, but the way that they maintain their organizational pattern is different. For example, the cells that comprise the nervous system have a chemical metabolism as their autonomous organization, but the nervous system itself has an organizational pattern based on a balance of neural activations. Varela would say that the nervous system is organizationally closed in the domain of neural activation. This idea of a domain roughly equates to what I have been calling “layers of reality”. That is, the organizational closure of the nervous system features entities and relations that aren’t the same as the entities and relations comprising cells, and the organizational closure of cells feature different entities and relations than are evident at the molecular level — even though the molecular/chemical interactions are what comprise the processes within cells.`}</p>
    <p>{`So autonomous systems can be made up of autonomous systems. Examples abound, such as termite mounds, human societies, and human organizations (like corporations and economies). These collections of organisms, which are themselves like organisms, have been called superorganisms (though this terminology is not currently in vogue). Human beings are superorganisms, but so are termite mounds and beehives, and corporations and societies.`}</p>
    <p>{`Despite the fact that we tend not to recognize corporations and societies as superorganisms doesn’t stop us from recognizing them as patterns, however. Again, the brain is a pattern recognizer, at root. It recognizes patterns on any number of scales. Our culture, itself an autonomous system formed and preserved through the behaviours of its constituents, extends the brain’s ability to map patterns in the world through shared meaning and coordination. We are not often conscious of culture’s role. Similarly, our cells are not aware of us and yet they play an indispensable role in keeping us alive and cognizant of our surroundings. Culture is a self-reinforcing, homeostatic entity with its own sense-making processes that depend on, but are separate from, the individuals comprising it.`}</p>
    <p>{`One of the most revolutionary ideas to come out of Maturana and Varela’s work on autopoiesis and autonomy is how those concepts are related to cognition. The dominant way of thinking about thinking and knowledge has been to consider the world as providing input to a computational device (usually the brain), and that device providing output to the world (usually through motoric outputs like behaviours). This perspective can be useful, but it obscures an even more useful perspective: That the form of an autonomous system’s world (the world as experienced by that system) is not carved out by perceptual processes that faithfully represent the world as it exists objectively, but rather by the homeostatic processes themselves as they adapt to changing circumstances in the organizational flow and connections. Remember that an autonomous system is organizationally closed, and so any internal space of that system represents not what is in the world, but rather the status of the system’s organization.`}</p>
    <p>{`The organization of the autonomous system can be perturbed by the outside world, but that perturbation is not sensation as it is commonly understood in the input/output paradigm. Relatively simple creatures, like single-celled organisms, have limited responses to environmental perturbations and so their world is similarly limited. While we can’t know exactly what it’s like to be an amoeba, an educated guess is that the amoeba experiences positive or negative valence in a handful of flavours depending on what part of its system the environment is impinging upon, and how the homeostatic mechanisms compensate for that perturbation. Human consciousness is more complex because there are vastly more ways that our systems can compensate for environmental perturbations, and vastly more ways that we can be perturbed by our environment.`}</p>
    <p>{`Our organizational structure, while generally autonomous in the relevant sense, is built up of many subsystems, some of which are autonomous in and of themselves. The philosopher Alva Noe and psychologist J. Kevin O’Regan have done much work on something called “sensorimotor contingencies” and their role in perception. A sensorimotor contingency is a kind of homeostatic loop that runs through both an organism’s sensory apparatus as well as its motor system (muscles or other actuators). The idea is that what we perceive is not tied to the mere passive transduction of an environment that gets represented more or less accurately, but rather that what something looks like has as much to do with our motor system as it does with our sensory system. One simple example is visual experience during paralysis. Because the vision system is synchronized deeply with eye and head movements, during paralysis an attempt to move the eye is interpreted as a movement of the environment. If this can be extrapolated to all sensory modalities, then we get a much different picture of how we are conscious of a world; one that aligns very well with the idea that the autonomous system encounters a world formed as much by its own organization as the energetic structure of the objective environment around it. To my mind, this is another excellent argument for the reality of patterns: Our very consciousness — the thing we have the most immediate access to and most intimate knowledge of — appears to be painted by pattern. If the patterns of our autonomous organization give rise to the world as we experience it, how can we not say that those patterns are real? They are indeed as real as our world.`}</p>
    <p>{`This gives rise to an interesting thought: If pattern within an autonomous system creates the subjective world of amoebas and humans alike, might not all autonomous systems have a subjective world determined by their organization? Might the immune system, in its autonomous organization, also have a kind of consciousness of its own? A world in which it inhabits that looks and feels nothing like the one we know? Does the nervous system, as an autonomous system, have subjective experiences that are different than those of our full organism? If this is the case, it could go some way toward explaining why there seems to be such a gap between the function of the brain and phenomenal consciousness: The brain may indeed be conscious, but it does not have the consciousness of the person but something entirely different.`}</p>
    <p>{`What about those autonomous organizations that are larger than organisms, like corporations or societies? Might they also have some form of consciousness germane to their form of organization, as simple or complex as that may be?`}</p>
    <p>{`We begin to see that pattern mapping has been something human societies have been doing since the beginning, building on our species’ excellence in this domain (compared to our planetary counterparts, AI notwithstanding). Religions are the result of an attempt, by a society, to capture, explain and exploit the real patterns around them, mostly at an interpersonal level, just as our materialist paradigm has attempted to do at a material level. From an eliminative materialist perspective, the ontological claims of most (if not all) religions are laughable. From our position of multiplism, however, we can see spiritual concepts as attempts at describing patterns at levels that we cannot detect with the five senses, but that we can otherwise perceive, such as trust, hope, friendship, generosity, justice, forgiveness and love.`}</p>
    <p>{`It is often said that the spiritual world cannot be perceived with the senses and this makes it sound like a domain apart from our physical world. But now we’re in a position to see that there is nothing spooky or supernatural about it, and that it is wholly continuous with the physical world in the same way that molecules are continuous with atoms. Just like there are differences in denizens and laws at all levels of reality, there are different denizens and laws in the spiritual world than at the level of organisms. The spiritual world is constituted by patterns in a social web, and I propose that the “soul” is equivalent to a person as it exists in this web of social relations. A raw organism, even with human DNA, isn’t a person. To be a person, we must exist as a pattern within this web of social relations. In our modern age, this includes not only interpersonal relationships, but also legal, economic, institutional, cultural and societal. The philosophical problem of personal identity is hereby solved as well: The two contenders for the basis of personal identity are typically thought to be either a continuity of body or a continuity of mind. The latter is closer to the mark, but there are numerous examples of discontinuities in both body and mind that don’t mark a distinction between persons. The missing ingredient is this web of social relations, which define each of us as a person (rather than just an organism, human or not). If we understand this, and accept pattern as basic to our ontological commitments, then we have a joint argument for the reality of the soul, and moreover, a way to understand the soul as being not identical with the body. Some of those social relations that constitute the soul are maintained after death, and so while death is the end of the body and admittedly a point of transformation for the soul, the death of the body doesn’t guarantee the death of the soul.`}</p>
    <p>{`There are a number of things that follow from this perspective. First, that the relationships a person has in his life is literally what defines him. Second, that those relationships can not only be numerous and strong, but they can be good or they can be bad. There’s no need to be too philosophical on this point: We all know the destructive power of bad relations. They can destroy the very conditions that make a person possible, if extreme. On the other hand, numerous and strong relations of a positive kind can provide the conditions for many connected persons to grow healthier within that web by making the web itself healthier. Ethics falls out of this, not as a divine command, but a homeostatic maintenance program for the patterns, upon which our very souls depend.`}</p>
    <p>{`Our society is a superorganism — a real pattern unto itself, but it is also a web of shared meaning that transforms us from walking cell colonies into people; a state that is more than the sum of its parts. A modern interpretation of a soul might be a pattern in the web of shared meaning, which in turn is the material basis for society. The spiritual world is the connection between souls, each connection making a small but additive effect on the health of the whole society. Recognizing the reality of the superorganism of which we are a part (and perhaps more above that — the ecosystem comes to mind) goes some way toward solving the crisis of meaning. Suddenly we are cells in a body, and the importance of keeping that body alive has implications that stretch far beyond our own mortal lives, and even those of our children. Given this realization, it’s hard not to feel the weight of responsibility, and hence meaning, that we all must bear to our own benefit, and to the benefit of the whole.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      